import { checkPermission } from '@/utils'
import { PAYMENT_TYPE_DIC, PAYMENT_TYPE_ONLINE_DIC } from '@/utils/constant'
import store from '@/store'
import { rechargeApplyRecordList, rechargeByBankApplyRecordList, backgroundManualAdjustmentList, userCreditLimitChangeList } from '@/api/financial/topUpRecord'

export const permission = {
  permit: checkPermission('externaladmin:financialManagement:topUpRecord:1:permit'),
  refuse: checkPermission('externaladmin:financialManagement:topUpRecord:1:refuse')
}

export const list = [
  {
    label: '线上充值',
    value: 0,
    childPermission: {
      exportBillBtn: checkPermission('externaladmin:financialManagement:topUpRecord:3')
    },
    getList: rechargeApplyRecordList,
    option: {
      searchLabelWidth: 88,
      menu: false,
      selection:true,
      column: [
        {
          label: '充值分销商',
          prop: 'nickName',
          search: true
        },
        {
          label: '账号',
          prop: 'accountName'
        },
        {
          label: '充值金额',
          prop: 'amount'
        },
        {
          label: '实付金额',
          prop: 'amount'
        },
        {
          label: '支付方式',
          prop: 'paymentType',
          search: true,
          type: 'select',
          dicData: PAYMENT_TYPE_ONLINE_DIC
        },
        {
          label: '交易编号',
          prop: 'tradeNo',
          search: true
        },
        {
          label: '支付流水号',
          prop: 'thirdpartTradeNo',
          search: true
        },
        {
          label: '充值状态',
          prop: 'approveStatus',
          type: 'select',
          dicType: 'approveStatusRecharge',
          search: true
        },
        {
          label: '充值时间',
          prop: 'applyTime',
          search: true,
          searchFormSlot: true,
          searchWidth: 555,
          searchLabelWidth: 100,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        }
      ]
    }
  },
  {
    label: '银行转账充值',
    value: 1,
    childPermission: {
      exportBillBtn: checkPermission('externaladmin:financialManagement:topUpRecord:3')
    },
    getList: rechargeByBankApplyRecordList,
    option: {
      searchLabelWidth: 88,
      align: 'center',
      menuFixed: 'normal',
      menuAlign: 'center',
      editBtn: false,
      delBtn: false,
      selection:true,
      column: [
        {
          label: '充值分销商',
          prop: 'nickName',
          search: true
        },
        {
          label: '账号',
          prop: 'accountName'
        },
        {
          label: '付款方',
          prop: 'contactName'
        },
        {
          label: '充值金额',
          prop: 'amount'
        },
        {
          label: '实付金额',
          prop: 'amount'
        },
        {
          label: '凭证',
          prop: 'receiptFilePath',
          width: 88,
          slot: true
        },
        {
          label: '联系方式',
          prop: 'mobile'
        },
        {
          label: '充值时间',
          prop: 'applyTime',
          search: true,
          searchFormSlot: true,
          searchWidth: 555,
          searchLabelWidth: 100,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          label: '审核状态',
          prop: 'approveStatus',
          dicType: 'statusType',
          type: 'select',
          search: true
        }
      ]
    },
    auditDialogOption: {
      resolve: {
        title: '是否同意用户余额的充值',
        confirmText: '同意',
        component: [
          {
            dangerouslyUseHTMLString: true,
            component: '审核通过后，该用户的余额相应增加，请谨慎操作',
            class: 'audit-dialog-title'
          },
          {
            component: 'customTable',
            option: {
              topPage: false,
              page: false,
              menu: false,
              column: [
                {
                  label: '分销商名称',
                  prop: 'nickName'
                },
                {
                  label: '登录账号',
                  prop: 'accountName'
                },
                {
                  label: '充值金额',
                  prop: 'amount'
                },
                {
                  label: '实付金额',
                  prop: 'amount'
                },
                {
                  label: '充值方式',
                  prop: 'paymentType',
                  type: 'select',
                  dicData: PAYMENT_TYPE_DIC
                },
                {
                  label: '充值时间',
                  prop: 'applyTime'
                },
                {
                  label: '上传凭证',
                  prop: 'receiptFilePath',
                  width: 80,
                  type: 'image',
                  isPreviewSrcList: true,
                  imgAttr: {
                    width: '60',
                    height: '60'
                  }
                }
              ]
            }
          }
        ]
      },
      reject: {
        title: '拒绝用户余额的充值 ',
        confirmText: '确定',
        component: [
          {
            component: 'customTable',
            componentAttrs: {
              option: {
                topPage: false,
                page: false,
                menu: false,
                column: [
                  {
                    label: '分销商名称',
                    prop: 'nickName'
                  },
                  {
                    label: '登录账号',
                    prop: 'accountName'
                  },
                  {
                    label: '充值金额',
                    prop: 'amount'
                  },
                  {
                    label: '实付金额',
                    prop: 'amount'
                  },
                  {
                    label: '充值方式',
                    prop: 'paymentType',
                    type: 'select',
                    dicData: PAYMENT_TYPE_DIC
                  },
                  {
                    label: '充值时间',
                    prop: 'applyTime'
                  },
                  {
                    label: '上传凭证',
                    prop: 'receiptFilePath',
                    width: 80,
                    type: 'image',
                    isPreviewSrcList: true,
                    imgAttr: {
                      width: '60',
                      height: '60'
                    }
                  }
                ]
              }
            }
          },
          {
            component: 'avueForm',
            componentAttrs: {
              option: {
                menuBtn: false,
                column: [
                  {
                    label: '拒绝备注',
                    prop: 'approveOpinion',
                    span: 24,
                    labelWidth: 80,
                    type: 'textarea',
                    maxlength: 100,
                    showWordLimit: true,
                    minRows: 4,
                    rules: {
                      required: true
                    }
                  }
                ]
              }
            }
          }
        ]
      }
    }
  },
  {
    label: '后台手工调整',
    value: 2,
    permission: checkPermission('externaladmin:financialManagement:topUpRecord:2:list'),
    childPermission: {
      exportBtn: checkPermission('externaladmin:financialManagement:topUpRecord:2:exportBtn')
    },
    getList: backgroundManualAdjustmentList,
    option: {
      searchLabelWidth: 88,
      menu: false,
      selection:true,
      column: [
        {
          label: '分销商名称',
          prop: 'nickName',
          search: true
        },
        {
          label: '登录账号',
          prop: 'accountName',
          search: true
        },
        {
          label: '调整金额',
          prop: 'tradeAmount'
        },
        {
          label: '备注',
          prop: 'remark'
        },
        {
          label: '操作人',
          prop: 'operator'
        },
        {
          label: '时间',
          prop: 'tradeTime',
          hide: true,
          search: true,
          type: 'daterange'
        },
        {
          label: '创建时间',
          prop: 'creatTime',
          valueFormat: 'yyyy-MM-dd'
        }
      ]
    }
  },
  {
    label: '用户信用额度变更',
    value: 3,
    permission: checkPermission('externaladmin:financialManagement:topUpRecord:3:list'),
    childPermission: {
      exportBtn: checkPermission('externaladmin:financialManagement:topUpRecord:3:exportBtn')
    },
    getList: userCreditLimitChangeList,
    option: {
      searchLabelWidth: 88,
      menu: false,
      selection:true,
      column: [
        {
          label: '分销商名称',
          prop: 'nickName',
          search: true
        },
        {
          label: '登录账号',
          prop: 'accountName',
          search: true
        },
        {
          label: '本次调整额度',
          prop: 'tradeAmount'
        },
        {
          label: '调整后信用额度',
          prop: 'remainingAmount'
        },
        {
          label: '备注',
          prop: 'remark'
        },
        {
          label: '操作人',
          prop: 'operator'
        },
        {
          label: '时间',
          prop: 'tradeTime',
          hide: true,
          search: true,
          type: 'daterange'
        },
        {
          label: '创建时间',
          prop: 'creatTime',
          valueFormat: 'yyyy-MM-dd'
        }
      ]
    }
  },
]

list.forEach((item) => store.dispatch('HandleOption', item.option))

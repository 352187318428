import request from '@/service/request'

// 线上充值记录后台显示
export function rechargeApplyRecordList(data) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/rechargeApplyRecordList',
    method: 'post',
    data
  })
}

// 银行充值记录后台显示
export function rechargeByBankApplyRecordList(data) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/rechargeByBankApplyRecordList',
    method: 'post',
    data
  })
}

// 后台充值审核通过
export function updateRechargeApplyRecordPermit(data) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/updateRechargeApplyRecordPermit',
    method: 'post',
    data
  })
}

// 后台手工调整
export function backgroundManualAdjustmentList(data) {
  return request({
    url: '/externaladmin/financeService/backgroundManualAdjustment/list',
    method: 'post',
    data
  })
}

// 信用额度余额
export function userCreditLimitChangeList(data) {
  return request({
    url: '/externaladmin/financeService/userCreditLimitChange/list',
    method: 'post',
    data
  })
}

// 后台充值审核拒绝
export function updateRechargeApplyRecordRefuse(data) {
  return request({
    url: '/externaladmin/financeService/paymentAccount/updateRechargeApplyRecordRefuse',
    method: 'post',
    data
  })
}
